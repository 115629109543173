import * as React from "react";

import { PreFilters, Size } from "@maxxton/cms-mxts-api";
import { getAvailability, updateAggregationsAndFilter, updateAvailability } from "./availabilityPagination.util";
import { isEqual, omit } from "lodash";

import { AvailabilityState } from "../../redux/reducers/availability.types";
import { DynamicFilter } from "../../redux/reducers/dynamicFilter.types";
import { selectFilters } from "./selectors";
import { useSelector } from "react-redux";

// eslint-disable-next-line max-lines-per-function
export const withPagination = <P extends object>(WrappedComponent: React.ComponentType<P>) => (props: any) => {
    const [page, setPage] = React.useState<number>(1);
    const [size, setSize] = React.useState<Size>();
    const [totalResults, setTotalResults] = React.useState<number | null>(null);
    const [availability, setAvailability] = React.useState<AvailabilityState | undefined>();
    const [isFetching, setIsFetching] = React.useState<Boolean>(false);
    const [preFilters, setPreFilters] = React.useState<PreFilters>();
    const [fieldsToAppend, setFieldsToAppend] = React.useState<Record<string, any>>({});
    const { dynamicFilter, availabilityState } = useSelector(selectFilters);
    const { sortingOption, markerIds } = dynamicFilter;
    const prevDynamicFilter = React.useRef<DynamicFilter | null>();

    const { resort, context } = props;

    const updateFetchStatus = (isFetching: boolean) => setIsFetching(isFetching);
    const updateTotalResultsCount = (count?: number) => setTotalResults((prev) => count || prev);
    const updateAvailabilityData = (data: AvailabilityState) => setAvailability(data);
    const updatePageNumber = (pageNo?: number) => setPage(pageNo || page + 1);
    const updateFieldsToAppend = (fieldsToAppend: Record<string, any>) => setFieldsToAppend(fieldsToAppend);
    const updateSizeInfo = (size: Size) => setSize(size);
    const updatePreFilters = (filters: PreFilters) => setPreFilters(filters);
    const dynamicFilterWithoutMarkers = React.useMemo(() => omit(dynamicFilter, ["dynamicMarkers", "markerIds", "onMouseOverOut"]), [dynamicFilter]);

    React.useEffect(() => {
        const currentCount = availabilityState?.availabilityResult?.response?.totalResources;
        if (!isEqual(currentCount, totalResults) && !props?.resort?.resortId) {
            updateTotalResultsCount(currentCount);
            updateAvailabilityData(availabilityState);
        }
    }, [availabilityState]);

    React.useEffect(() => {
        const markerIds = availability?.availabilityResult?.response?.resourceMarkers?.map((marker) => marker?.resourceId);
        if (!isEqual(markerIds, dynamicFilter?.markerIds)) {
            updatePageNumber(1);
            updatePaginatedResults(1);
        }
    }, [dynamicFilter.markerIds]);

    React.useEffect(() => {
        if (page !== 1) {
            updatePaginatedResults();
        }
    }, [page]);

    React.useEffect(() => {
        updatePageNumber(1);
        updatePaginatedResults(1);
    }, [dynamicFilter?.sortingOption, size]);

    React.useEffect(() => {
        if (!isEqual(prevDynamicFilter?.current, dynamicFilterWithoutMarkers) && !dynamicFilter?.markerIds?.length) {
            updatePageNumber(1);
            updatePaginatedResults(1);
            prevDynamicFilter.current = dynamicFilterWithoutMarkers;
        }
    }, [dynamicFilterWithoutMarkers]);

    const updatePaginatedResults = async (pageNo?: number) => {
        if (size && preFilters) {
            const availabilityAndPaginatedResults = await getAvailability(getPaginatedRequestPayload(pageNo));
            if (!isEqual(availabilityState, availabilityAndPaginatedResults)) {
                updateAvailability({
                    availabilityAndPaginatedResults,
                    fieldsToAppend: totalResults && (pageNo || page) !== 1 ? fieldsToAppend : {},
                    resort,
                    updateTotalResultsCount,
                    updateFetchStatus,
                    context,
                    updateAvailabilityData,
                });
            }
        }
    };

    const getPaginatedRequestPayload = (pageNo?: number) => {
        const { sortOnArrivalDate, showAmenitiesCount } = props.options;
        const { updatedDynamicFilter, customAggregations } = updateAggregationsAndFilter(props?.resort, dynamicFilter, { sortOnArrivalDate, showAmenitiesCount }) || {};
        const ids = markerIds?.length ? markerIds : undefined;
        return {
            paginationData: { page: pageNo || page, size, sort: sortingOption || "highToLowRating", ids, preFilters },
            dynamicFilter: updatedDynamicFilter,
            context,
            availabilityState,
            customAggregations,
            updateFetchStatus,
        };
    };

    return (
        <WrappedComponent
            {...(props as any)}
            paginatedAvailability={availability}
            updatePageNumber={updatePageNumber}
            totalResults={totalResults}
            isFetching={isFetching}
            updateFieldsToAppend={updateFieldsToAppend}
            updateSizeInfo={updateSizeInfo}
            fieldsToAppend={fieldsToAppend}
            updatePreFilters={updatePreFilters}
        />
    );
};
