import { AggregationOptions, AvailabilityGetOptions, AvailabilityUpdateOptions } from "./pagination.type";
import { AvailabilityAction, AvailabilityActionType } from "../../redux/actions/availabilityAction";
import { ElasticResult, Resort } from "@maxxton/cms-mxts-api";

import { AMENITIES_COUNT_AGGREGATION } from "../../plugins/dynamic/unitsearchContainer/UnitsearchContainerWidget";
import { ActionType } from "../../redux/actions";
import { AvailabilityUtil } from "../availability.util";
import { DynamicFilter } from "../../redux/reducers/dynamicFilter.types";
import { SORTED_RESOURCE_AGGREGATION } from "../../plugins/dynamic/typesearchContainer/TypesearchContainerWidget";

export const updateAggregationsAndFilter = (resort: Resort, dynamicFilter: DynamicFilter, aggregation?: AggregationOptions) => {
    let updatedDynamicFilter;
    let customAggregations;
    if (resort?.resortId) {
        customAggregations = [];
        if (aggregation?.sortOnArrivalDate) {
            customAggregations.push(SORTED_RESOURCE_AGGREGATION);
        }
        if (aggregation?.showAmenitiesCount) {
            customAggregations.push(AMENITIES_COUNT_AGGREGATION);
        }
        updatedDynamicFilter = {
            ...dynamicFilter,
            resortids: [resort.resortId],
            shouldFetchResorts: false,
        };
    }
    return { updatedDynamicFilter: updatedDynamicFilter || dynamicFilter, customAggregations };
};

export const getAvailability = async (options: AvailabilityGetOptions) => {
    const { paginationData, dynamicFilter, customAggregations, context, updateFetchStatus } = options;
    if (paginationData) {
        updateFetchStatus(true);
        if (paginationData?.page === 1) {
            const fetchingAction: AvailabilityAction = {
                actionType: AvailabilityActionType.fetching,
                type: ActionType.Availability,
            };
            const dispatchAction = context?.reduxStore?.store?.dispatch;
            dispatchAction && dispatchAction(fetchingAction);
        }
        const availabilityAndPaginatedResults = await AvailabilityUtil.getAvailabilityByDynamicFilter(dynamicFilter, { customAggregations }, context, paginationData);
        return availabilityAndPaginatedResults;
    }
};

function appendPaginatedResults(fieldsToAppend: Partial<ElasticResult>, paginatedResults: ElasticResult) {
    for (const key in fieldsToAppend) {
        if (fieldsToAppend.hasOwnProperty(key)) {
            const elasticKey = key as keyof ElasticResult;
            const appendFields = fieldsToAppend[elasticKey];
            const paginatedResult = paginatedResults[elasticKey];
            if (Array.isArray(appendFields) && Array.isArray(paginatedResult)) {
                const mergedData = Array.from(new Map([...appendFields, ...paginatedResult]?.map((data: any) => [data?.resourceId, data])).values());
                paginatedResults[elasticKey] = mergedData as any;
            }
        }
    }
    return paginatedResults;
}

export const updateAvailability = (options: AvailabilityUpdateOptions) => {
    const { availabilityAndPaginatedResults, fieldsToAppend, resort, updateTotalResultsCount, updateFetchStatus, context, updateAvailabilityData } = options;
    let availabilityResult = availabilityAndPaginatedResults?.availabilityResult;
    if (availabilityResult && fieldsToAppend && Object.keys(fieldsToAppend)?.length) {
        const mergedResponse = appendPaginatedResults(fieldsToAppend, availabilityResult?.response);
        availabilityResult = { ...availabilityResult, response: mergedResponse };
    }
    const action: AvailabilityAction = {
        actionType: AvailabilityActionType.fetched,
        type: ActionType.Availability,
        payload: {
            availabilityResult,
            env: availabilityAndPaginatedResults?.env,
            availabilityRequest: availabilityAndPaginatedResults?.availabilityRequest,
        },
    };
    if (action.payload) {
        updateAvailabilityData(action.payload);
    }
    updateTotalResultsCount(availabilityResult?.response?.totalResources);
    updateFetchStatus(false);

    if (!resort?.resortId) {
        const dispatchAction = context?.reduxStore?.store?.dispatch;
        dispatchAction && dispatchAction(action);
    }
};
