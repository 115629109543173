import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classNames from "classnames";
import * as moment from "moment";

import { AVAILABILITY_CONSTANTS, DATE_FORMAT, MXTS, ResultStyleSelector } from "../../../utils/constants";
import {
    AccoKind,
    Address,
    Aggregation,
    Amenity,
    ApiCallOptions,
    AvailabilityResult,
    BookRestrictions,
    ElasticFacetV2,
    Facet,
    PagedResult,
    PreFilters,
    Resort,
    Resource,
    ResourceGroup,
    Size,
    StayPeriodDef,
    WithAddressResult,
    getAllWithAddress,
} from "@maxxton/cms-mxts-api";
import { AccommodationType, accoKindById, addressByManagerId, getMxtsEnv, resourceById } from "../../mxts";
import { WebContent as CmsApiWebContent, Site, WithId, globalLogger } from "@maxxton/cms-api";
import { DispatchOpenLinkedTabOptions, dispatchOpenLinkedTabAction } from "../../resultsPanel/resultsPanelUtil";
import { RESTRICTION_RULE_OPTIONS, getAllBookRestrictionsByResourceIds, validateBookRestrictionConditions } from "../../../utils/bookRestriction.util";
import { RenderVirtualizedList, withVirtualization } from "../../../utils/virtualization/withVirtualization";
import { StateHandler, warmupState } from "../../../utils/cacheWarmup.util";
import { generateRandomKey, getHideWidgetClass, isClientLoggedIn, isEqual } from "../../../components/utils";
import { getDefaultStayCode, stayPeriodDefById } from "../../mxts/mxts.util";
import { getGlobalFields, isOnLandingPage } from "../../../utils/globalFields";
import { getI18nLocaleString, wrapProps } from "../../../i18n";
import { getLocalizedContent, isMobileDeviceDetected } from "../../../utils/localizedContent.util";
import { isClientSide, isServerSide } from "../../../utils/generic.util";
import { isEmpty, pick } from "lodash";

import { ActionType } from "../../../redux/actions";
import { AmenitiesAction } from "../../../redux/actions/amenitiesAction";
import { AvailabilityAction } from "../../../redux/actions/availabilityAction";
import { AvailabilityState } from "../../../redux/reducers/availability.types";
import { Button } from "reactstrap";
import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { CurrentLocale } from "../../../app.types";
import { Dispatch } from "redux";
import { DomainObjectUtil } from "../../../utils/domainobject.util";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { DynamicWidgetBaseProps } from "../dynamicWidget.types";
import { ErrorBoundary } from "../../../components/ErrorBoundary";
import { FilterChangeAction } from "../../../redux/actions/dynamicFilterAction.types";
import { Loader } from "../../../components/Loader";
import { LocalizedDcOptions } from "../locationSearchContainer";
import { MarkerDetails } from "../../page/map/mapWidget.types";
import { Pagination } from "../../../utils/Pagination/pagination.type";
import { PriceRangeCriteria } from "../priceRange/priceRange.enum";
import { ResultsPanelAction } from "../../../redux/actions/resultsPanelAction";
import { State } from "../../../redux";
import { UserInterfaceState } from "../../../redux/reducers/userInterfaceReducer";
import { WidgetOptions } from "./";
import { cancelable } from "../../../promise/cancelable";
import { connect } from "react-redux";
import { dynamicFilterPickObjects } from "../../../utils/datalayer.util";
import { dynamicFilterType } from "../../../redux/reducers/dynamicFilter.enum";
import { fontColorPicker } from "../../../utils/colorpicker.util";
import { getDeviceName } from "../../../utils/logs.util";
import { getStayPeriodDefFilters } from "../../../utils/stayPeriodDefs.util";
import namespaceList from "../../../i18n/namespaceList";
import { renderNoResultsFoundContent } from "../containerWidget.util";
import { renderPageWidgets } from "../../widget";
import { scroller } from "react-scroll";
import { withPagination } from "../../../utils/Pagination/withPagination";

const { APPLICATION } = AVAILABILITY_CONSTANTS;
export interface TypesearchContainerProps extends TypesearchContainerBaseProps, Pagination, TypesearchContainerStoreProps, TypesearchContainerDispatchProps {}

interface TypesearchContainerBaseProps extends DynamicWidgetBaseProps<WidgetOptions> {
    classNames: string;
    options: WidgetOptions;
    context: CMSProvidedProperties;
    children: Array<{ element: JSX.Element; options: WidgetOptions }>;
    childrenList: JSX.Element[];
    childrenGrid: JSX.Element[];
    childrenMobile: JSX.Element[];
    childrenTablet: JSX.Element[];
    markerIds?: number[];
    showLoadMoreButton?: boolean;
    resort?: Resort;
    warmupState?: TypesearchContainerState;
    renderVirtualizedList?: RenderVirtualizedList;
}

export interface StayPeriodDefSelection {
    stayPeriodDefIds: number[];
    isIncluded: boolean;
}

export interface FetchParameters {
    stayPeriodDef: StayPeriodDefSelection;
    address: {
        isIncluded: boolean;
    };
}

export interface FetchResult {
    resorts: Resort[];
    fetchedResources?: Resource[];
    stayPeriodDefs?: StayPeriodDef[];
    addresses?: Address[];
    accoKinds?: AccoKind[];
}

interface TypesearchContainerStoreProps {
    dynamicFilter: DynamicFilter;
    availabilityState: AvailabilityState;
    userInterfaceState: UserInterfaceState;
    amenities: ElasticFacetV2 | undefined;
}

// CRPProps are passed to all individual CRPs and all widgets inside of them.
// We also pass the dynamicFilter to the children, but we can't add that to this interface because the children often already have the dynamicFilter in their props.
export interface CrpProps {
    useCrpProps?: boolean;
    accommodationType?: AccommodationType;
    newRateTypeId?: number;
    unitBookUri?: string;
    key?: string | number;
    amenityCodes?: string[];
    dispatchOpenLinkedTabAction?: (options: DispatchOpenLinkedTabOptions) => void;
    resort?: Resort;
}

interface AmenitiesState {
    amenities: ElasticFacetV2;
}

interface TypesearchContainerDispatchProps {
    dispatchAction: Dispatch<FilterChangeAction | AvailabilityAction | ResultsPanelAction>;
}

enum PriceType {
    PRICE_INCLUSIVE = "priceInclusive",
    BASE_NIGHT_PRICE_INCLUSIVE = "baseNightPriceInclusive",
}

export interface TypesearchContainerState {
    disableWidget: boolean;
    accommodationTypes?: AccommodationType[] | undefined;
    mapSynchedAccommodationTypes?: AccommodationType[] | undefined;
    onMouseOver: MarkerDetails[];
    highlightedMarker: number;
    zoomedAccommodationTypes: number[];
    showMap: boolean;
    fetchingMoreTypes: boolean;
    minPrice?: number;
    maxPrice?: number;
    infiniteLoading: boolean;
    totalAccommodationTypes: number;
    deviceType: string;
    resourceid?: number | null;
    directSearchInput?: string;
    webContent?: (CmsApiWebContent & WithId) | null;
    template?: JSX.Element[] | null;
    errorFetchingTypes: string;
    resorts?: Resort[];
    stayPeriodDefs?: StayPeriodDef[];
    addresses?: Address[];
    fetchedResources?: Resource[];
    nextResources?: ResourceGroup;
    amenityCodes?: string[];
    isLoading: boolean;
    availabilityState: AvailabilityState;
    toggleChildren: string[];
}

export const SORTED_RESOURCE_AGGREGATION: Aggregation = {
    name: "RESOURCE_GROUP",
    field: "RESOURCE_ID",
    type: "GROUP",
    sortField: "ARRIVAL_DATE",
};

export const AMENITIES_COUNT_AGGREGATION: Aggregation = {
    count: "RESOURCE_ID",
    field: "ALL_AMENITIES",
    name: "ALL_AMENITIES_FACET",
    size: 1000,
    type: "FACET",
};

// eslint-disable-next-line max-len
export class TypesearchContainerWidgetBase extends React.Component<TypesearchContainerProps, TypesearchContainerState, AmenitiesState> {
    private typeSearchScroll: HTMLDivElement | null;
    private buttonDOM: HTMLButtonElement | null;
    private controller: AbortController = new AbortController();
    private cancelGetAvailability?: () => void;
    private cancelFetchingDetails?: () => void;

    public static async warmupCache(props: TypesearchContainerProps): Promise<TypesearchContainerState> {
        return warmupState(props, TypesearchContainerWidgetBase.defaultState(props), async (stateHandler) => {
            props.dynamicFilter = props.context.reduxStore.store.getState().dynamicFilter;
            if (props.dynamicFilter.amenities) {
                await TypesearchContainerWidgetBase.handleAmenities(props, stateHandler, props.dynamicFilter.amenities);
            }
            TypesearchContainerWidgetBase.updatePaginationData(props);
        });
    }

    public static async initDefaultFilter(props: TypesearchContainerProps): Promise<void> {
        const dynamicFilter = await TypesearchContainerWidgetBase.populateDynamicFilterWithPreSelectedFilters(props);
        if (!isEqual(props?.dynamicFilter, dynamicFilter)) {
            const { dispatchAction } = props;
            dispatchAction({
                type: ActionType.FilterChange,
                filter: dynamicFilterType.blendFilters,
                payload: dynamicFilter,
                dispatchAction,
            });
        }
    }

    private static defaultState(props: TypesearchContainerProps): TypesearchContainerState {
        return {
            disableWidget: true,
            onMouseOver: [],
            highlightedMarker: -1,
            zoomedAccommodationTypes: [],
            showMap: true,
            fetchingMoreTypes: false,
            infiniteLoading: true,
            totalAccommodationTypes: 0,
            deviceType: "",
            errorFetchingTypes: "",
            isLoading: true,
            availabilityState: props.availabilityState,
            toggleChildren: [],
        };
    }
    private intersectionObserver: IntersectionObserver | null = null;
    private sentinelRef: React.RefObject<HTMLDivElement>;
    private prevSentinelRefValue: HTMLDivElement | null = null;
    constructor(props: TypesearchContainerProps) {
        super(props);
        this.state = {
            ...TypesearchContainerWidgetBase.defaultState(props),
            ...(props.warmupState || {}),
        };
        this.renderTypeSearchPanel = this.renderTypeSearchPanel.bind(this);
        this.onIntersection = this.onIntersection.bind(this);
        this.sentinelRef = React.createRef();

        // TypesearchContainerWidgetBase.populateDynamicFilterWithPreSelectedFilters(props).then(async (dynamicFilter: DynamicFilter) => {
        //     if (!isEqual(this.props?.dynamicFilter, dynamicFilter)) {
        //         const { dispatchAction } = this.props;
        //         dispatchAction({
        //             type: ActionType.FilterChange,
        //             filter: dynamicFilterType.blendFilters,
        //             payload: dynamicFilter,
        //             dispatchAction,
        //         });
        //     }
        //     const env: ApiCallOptions = await getMxtsEnv(props.context, props.context.currentLocale.code);
        //     TypesearchContainerWidgetBase.fetchAvailabilityResults(this.props, this, dynamicFilter, env, this);
        // });
    }
    private static updatePaginatedDataToDynamicFilter(props: Readonly<TypesearchContainerProps>, sizeInfo: Size, preFilters?: PreFilters): void {
        const action: FilterChangeAction = {
            type: ActionType.FilterChange,
            filter: dynamicFilterType.paginatedData,
            payload: {
                paginatedData: {
                    typesearch: {
                        size: sizeInfo,
                        preFilters,
                    },
                },
            },
        };
        props.dispatchAction(action);
    }

    public componentDidMount() {
        const { dynamicFilter, options, context, dispatchAction } = this.props;
        this.setState({ disableWidget: !isClientLoggedIn() });
        if (options.resultsPanelMobile && screen.width < 768) {
            this.setState({ deviceType: "mobile" });
        } else if (options.resultsPanelTablet && screen.width > 767 && screen.width < 1025) {
            this.setState({ deviceType: "tablet" });
        }
        if (dynamicFilter.amenities) {
            TypesearchContainerWidgetBase.handleAmenities(this.props, this, dynamicFilter.amenities, this);
        }
        TypesearchContainerWidgetBase.updatePaginationData(this.props);
        if (context.site.showUnavailableTypes) {
            dispatchAction({
                type: ActionType.FilterChange,
                filter: dynamicFilterType.includeBookedPeriods,
                payload: { includeBookedPeriods: context.site.showUnavailableTypes },
            });
        }
    }

    private static updatePaginationData = (props: Readonly<TypesearchContainerProps>) => {
        const { options, updateSizeInfo, updatePreFilters } = props;
        const sizeInfo = { initialSize: +(options?.defaultNumberOfTypes || 5), incrementalSize: +(options?.nextNumberOfTypes || 5) };
        const preFilters = TypesearchContainerWidgetBase.getPreFilters(props);
        if (updateSizeInfo) {
            updateSizeInfo(sizeInfo);
        }
        if (updatePreFilters) {
            updatePreFilters(preFilters);
        }
        TypesearchContainerWidgetBase.updatePaginatedDataToDynamicFilter(props, sizeInfo, preFilters);
    };

    public componentDidUpdate(prevProps: Readonly<TypesearchContainerProps>, prevState: Readonly<TypesearchContainerState>) {
        const {
            options: { enableVirtualization },
            userInterfaceState: { resultLayoutViewOptions },
            fieldsToAppend,
            updateFieldsToAppend,
        } = this.props;
        const displayGridListMap = resultLayoutViewOptions?.resultLayoutDisplayType;
        const results: Element | null = document.querySelector(".search-results-wrapper");
        const map: Element | null = document.querySelector(".map-wrapper");
        const hasChild: NodeListOf<Element> = document.querySelectorAll(".list-grid-map-view");
        const resources = this.state?.availabilityState?.availabilityResult?.response?.resources;
        if (hasChild?.length) {
            Array.from(hasChild).forEach((child) => {
                if (displayGridListMap === "list-view" || displayGridListMap === "grid-view") {
                    if (child?.contains(results as any) === false) {
                        child.classList.add("d-none");
                    } else {
                        child.classList.remove("d-none");
                    }
                } else if (displayGridListMap === "list-view-map" || displayGridListMap === "grid-view-map") {
                    if (child?.contains(results as any) || child?.contains(map as any)) {
                        child.classList.remove("d-none");
                    }
                }
            });
        }
        if (resources && !isEqual(fieldsToAppend?.resources, resources) && updateFieldsToAppend) {
            updateFieldsToAppend({ resources });
        }

        if (this.sentinelRef.current !== this.prevSentinelRefValue && this.sentinelRef.current) {
            this.prevSentinelRefValue = this.sentinelRef.current;
            this.setupObserver();
        }
    }

    private setupObserver() {
        if (this.sentinelRef.current) {
            this.intersectionObserver = new IntersectionObserver(this.onIntersection);
            this.intersectionObserver.observe(this.sentinelRef.current);
        }
    }

    private onIntersection(entries: IntersectionObserverEntry[]) {
        const { accommodationTypes, fetchingMoreTypes } = this.state;
        const { totalResults, updatePageNumber } = this.props;
        if (updatePageNumber && entries[0]?.isIntersecting && !fetchingMoreTypes && accommodationTypes && totalResults && accommodationTypes?.length < totalResults) {
            updatePageNumber();
        }
    }

    public componentWillUnMount() {
        this.controller.abort();
        if (this.intersectionObserver) {
            this.intersectionObserver?.disconnect();
        }
    }

    // eslint-disable-next-line max-lines-per-function
    public UNSAFE_componentWillReceiveProps(nextProps: Readonly<TypesearchContainerProps>) {
        const availabilityResult: AvailabilityResult | undefined = this.props.availabilityState?.availabilityResult;
        const nextAvailabilityResult: AvailabilityResult | undefined = nextProps.availabilityState?.availabilityResult;
        const { minprice, maxprice, amenities, startdate: nextStartdate } = nextProps.dynamicFilter;
        const { startdate } = this.props.dynamicFilter;
        const { totalResults, paginatedAvailability } = nextProps;
        const nextResources = nextAvailabilityResult?.response.resources;
        const resources = availabilityResult?.response.resources;
        if (nextResources && !isEqual(nextResources, resources)) {
            // Note:- When show a button to search results in map bounds it will set latest resources when we move the map.
            if (nextResources.length) {
                TypesearchContainerWidgetBase.fetchAvailabilityResults(nextProps, this, nextProps.dynamicFilter, undefined, this);
            } else {
                TypesearchContainerWidgetBase.errorHandler(nextProps, this);
            }
        }

        const dynamicFilterPickValues = Object.values(dynamicFilterPickObjects);
        if (
            Object.keys(pick(nextProps.dynamicFilter, dynamicFilterPickValues)).some(
                (key) => !isEqual(this.props.dynamicFilter[key as keyof DynamicFilter], nextProps.dynamicFilter[key as keyof DynamicFilter])
            )
        ) {
            TypesearchContainerWidgetBase.fetchAvailabilityResults(nextProps, this, nextProps.dynamicFilter, undefined, this);
        }
        let zoomedAccommodationTypes: number[] = this.state.zoomedAccommodationTypes;
        const displayGridListMap: string | undefined = nextProps.userInterfaceState.resultLayoutViewOptions?.resultLayoutDisplayType;
        let totalAccommodationTypes: number = totalResults || 0;
        const markerIds: number[] | undefined = nextProps.dynamicFilter.markerIds;
        if (markerIds && this.state.zoomedAccommodationTypes !== markerIds) {
            zoomedAccommodationTypes = markerIds;
        }
        if (displayGridListMap !== undefined) {
            totalAccommodationTypes = totalResults || 0;
        }
        const selectedDirectSearchId: number | undefined = nextProps.dynamicFilter.selectedDirectSearchId;
        if (selectedDirectSearchId !== null && this.state.resourceid !== selectedDirectSearchId) {
            this.setState({ resourceid: selectedDirectSearchId }, () => {
                TypesearchContainerWidgetBase.updateAccoTypes(nextProps, this, undefined, this.state, this);
            });
        }

        const directSearchInput: string | undefined = nextProps.dynamicFilter.directSearchInput;
        if (directSearchInput !== undefined && this.state.directSearchInput !== directSearchInput) {
            this.setState({ directSearchInput }, () => {
                TypesearchContainerWidgetBase.updateAccoTypes(nextProps, this, undefined, this.state, this);
            });
        }

        if (amenities && !isEqual(this.props.dynamicFilter.amenities, amenities)) {
            TypesearchContainerWidgetBase.handleAmenities(this.props, this, amenities, this);
        }

        this.setState(
            {
                zoomedAccommodationTypes,
                totalAccommodationTypes,
            },
            () => {
                if (displayGridListMap === "list-view-map" || displayGridListMap === "grid-view-map") {
                    this.refreshAccommodationTypes(this.state.zoomedAccommodationTypes);
                }
            }
        );

        if (
            (!isEqual(minprice, this.props.dynamicFilter.minprice) || !isEqual(maxprice, this.props.dynamicFilter.maxprice)) &&
            ((this.state.minPrice && this.state.minPrice !== minprice) || (this.state.maxPrice && this.state.maxPrice !== maxprice))
        ) {
            this.setState({ minPrice: minprice, maxPrice: maxprice }, () => {
                TypesearchContainerWidgetBase.updateAccoTypes(nextProps, this, undefined, this.state, this);
            });
        }

        if (!isEqual(startdate, nextStartdate)) {
            TypesearchContainerWidgetBase.updateAccoTypes(nextProps, this, undefined, this.state, this);
        }

        if (!isEqual(this.props?.paginatedAvailability, paginatedAvailability) && nextProps.resort?.resortId) {
            const availabilityState = {
                ...this.state.availabilityState,
                availabilityResult: undefined,
            };
            this.setState(
                {
                    availabilityState: {
                        ...availabilityState,
                        ...nextProps?.paginatedAvailability,
                        fetching: false,
                    },
                },
                () => TypesearchContainerWidgetBase.updateAccoTypes(nextProps, this, true, this.state, this)
            );
        }
        if (!isEqual(nextProps.isFetching, this.state.fetchingMoreTypes)) {
            this.setState({ fetchingMoreTypes: nextProps.isFetching || false });
        }
        const accommodationTypesIds = nextResources?.map((accommodationType) => accommodationType?.resourceId);
        if (accommodationTypesIds?.length && !isEqual(accommodationTypesIds, zoomedAccommodationTypes)) {
            this.setState({ zoomedAccommodationTypes: accommodationTypesIds });
        }
    }

    public shouldComponentUpdate(nextProps: Readonly<TypesearchContainerProps>, nextState: Readonly<TypesearchContainerState>): boolean {
        if (!isEqual(this.props.availabilityState, nextProps.availabilityState)) {
            return false;
        }
        return (
            !isEqual(this.state, nextState) ||
            !isEqual(this.props.dynamicFilter, nextProps.dynamicFilter) ||
            nextState.errorFetchingTypes !== this.state.errorFetchingTypes ||
            this.state.availabilityState.fetching !== nextState.availabilityState.fetching
        );
    }

    // eslint-disable-next-line max-lines-per-function
    public render(): JSX.Element | null {
        const {
            childrenList,
            childrenGrid,
            childrenMobile,
            childrenTablet,
            options,
            context,
            userInterfaceState,
            renderVirtualizedList,
            updatePageNumber,
            totalResults,
            resort,
            dynamicFilter,
        } = this.props;
        const { currentLocale, site } = context;
        const resultLayoutDisplayType = userInterfaceState.resultLayoutViewOptions?.resultLayoutDisplayType;
        const { accommodationTypes, mapSynchedAccommodationTypes, fetchingMoreTypes, infiniteLoading, totalAccommodationTypes, deviceType, isLoading, availabilityState } = this.state;
        const selectedAccommodationResource = availabilityState?.availabilityResult?.response?.selectedAccommodationResource?.[0] as AccommodationType;

        const hideWidget: string | null = getHideWidgetClass(options, this.state.disableWidget);
        const loader: JSX.Element = options.resultsPanelGrid ? (
            <Loader type="typeSearchContainerGrid" views="type-search-container-grid" />
        ) : (
            <Loader type="typeSearchContainerList" views="type-search-container-list" />
        );

        if (isLoading || isServerSide()) {
            return loader;
        }

        const loadMore: JSX.Element = (
            <div>
                {options.showLoadMoreButton &&
                mapSynchedAccommodationTypes &&
                (childrenGrid.length || childrenList.length || childrenMobile.length || childrenTablet.length) &&
                totalResults &&
                updatePageNumber &&
                mapSynchedAccommodationTypes?.length < totalResults ? (
                    <div className="panel text-center">
                        <Button
                            onClick={() => updatePageNumber()}
                            className="button button--l  button--secondary book-btn"
                            innerRef={(buttonDOM: any) => {
                                this.buttonDOM = buttonDOM;
                            }}
                        >
                            <FontAwesome name="spinner" className={classNames("searchfacet-progress", fetchingMoreTypes ? "in-progress" : "no-progress")} />
                            {" " + getI18nLocaleString(namespaceList.widgetTypeSearch, "fetchNextItems", currentLocale, site)}
                            <FontAwesome name="spinner" className={"searchfacet-progress no-progress"} />
                        </Button>
                    </div>
                ) : null}
                {!options.showLoadMoreButton && infiniteLoading && loader}
            </div>
        );
        if (hideWidget === null) {
            return null;
        }
        let displayTypeContainer = "";
        let displayTypeChild = "";
        let childrenArray: JSX.Element[] = [];
        const filterRegExp = new RegExp("\\$filteredCount");
        const totalRegExp = new RegExp("\\$totalCount");
        if (resultLayoutDisplayType === "grid-view" || resultLayoutDisplayType === "grid-view-map" || (resultLayoutDisplayType === undefined && options.resultsPanelGrid)) {
            childrenArray = this.props.childrenGrid;
            displayTypeContainer = "row";
            // eslint-disable-next-line max-len
            displayTypeChild = `${options.columns ? "col-sm-" + options.columns : ""} ${options.columnsResp ? "col-" + options.columnsResp : "col"} ${
                options.columnsTab ? "col-md-" + options.columnsTab : ""
            } ${options.columnsDesk ? "col-lg-" + options.columnsDesk : ""}`;
        } else if (deviceType === "mobile") {
            childrenArray = this.props.childrenMobile;
        } else if (deviceType === "tablet") {
            childrenArray = this.props.childrenTablet;
        } else {
            childrenArray = this.props.childrenList;
        }
        if (resultLayoutDisplayType === "map-view") {
            return null;
        }
        if (accommodationTypes?.length && totalResults) {
            if (mapSynchedAccommodationTypes?.length) {
                let resultCount = "";
                const filteredIndex = mapSynchedAccommodationTypes?.length;
                if (mapSynchedAccommodationTypes && totalAccommodationTypes && (!options.resultStyleSelector || options.resultStyleSelector === ResultStyleSelector.DEFAULT)) {
                    resultCount = getI18nLocaleString(namespaceList.genericCrud, "showingCountValue", currentLocale, site)
                        .replace(filterRegExp, filteredIndex.toString())
                        .replace(totalRegExp, totalResults.toString());
                } else if (options.resultStyleSelector === ResultStyleSelector.SHOWING_X_RESULT) {
                    resultCount = getI18nLocaleString(namespaceList.genericCrud, "showingCountValueStyle1", currentLocale, site).replace(filterRegExp, filteredIndex.toString());
                } else {
                    resultCount = getI18nLocaleString(namespaceList.genericCrud, "showingCountValueStyle2", currentLocale, site).replace(filterRegExp, filteredIndex.toString());
                }
                let updatedAccommodationTypes = mapSynchedAccommodationTypes;
                if (selectedAccommodationResource && dynamicFilter.previouslySelectedResourceId) {
                    updatedAccommodationTypes = mapSynchedAccommodationTypes.filter((item) => item.resourceId !== dynamicFilter.previouslySelectedResourceId);
                }
                return (
                    <ErrorBoundary>
                        <div
                            className={`search-results-wrap ${hideWidget} type-search`}
                            ref={(input) => {
                                this.typeSearchScroll = input;
                            }}
                        >
                            {options?.resultStyleSelector !== ResultStyleSelector.DISPLAY_STYLE_HIDE && <div className="result-count">{resultCount}</div>}
                            {selectedAccommodationResource && (
                                <div className="accommodation-selection">
                                    <span className="accommodation-selection-title"> {getI18nLocaleString(namespaceList.widgetTypeSearch, "selectedAccommodation", currentLocale, site)}</span>
                                    <div className="accommodation-selection-details">
                                        {this.renderUnvirtualizedList({ childrenArray, slicedAccommodationTypes: [selectedAccommodationResource], displayTypeChild })}
                                    </div>
                                </div>
                            )}
                            <div
                                // eslint-disable-next-line max-len
                                className={`search-results-wrapper ${resultLayoutDisplayType || ""} ${displayTypeContainer}`}
                            >
                                {childrenArray.length > 0 &&
                                    (options.enableVirtualization && renderVirtualizedList
                                        ? renderVirtualizedList(updatedAccommodationTypes, this.renderTypeSearchPanel, {
                                              childrenArray,
                                              displayType: displayTypeChild,
                                          })
                                        : this.renderUnvirtualizedList({ childrenArray, slicedAccommodationTypes: updatedAccommodationTypes, displayTypeChild }))}
                            </div>
                            {loadMore}
                        </div>
                        {!options.showLoadMoreButton && <div ref={this.sentinelRef}>{totalResults > mapSynchedAccommodationTypes?.length && fetchingMoreTypes && !resort?.resortId && loader}</div>}
                    </ErrorBoundary>
                );
            } else if (!this.props.availabilityState.fetching) {
                return (
                    <div className="unit-search no-results">
                        <h4 className="no-type-results-header">{getI18nLocaleString(namespaceList.widgetMap, "tryAgain", currentLocale, site)}</h4>
                        <p className="no-type-results-body">{getI18nLocaleString(namespaceList.widgetMap, "noResults", currentLocale, site)}</p>
                    </div>
                );
            }
        } else if (this.state.errorFetchingTypes) {
            return <div className={`${hideWidget}`}>{renderNoResultsFoundContent({ noResultsFoundWebContent: this.state.webContent, noResultsFoundTemplate: this.state.template, context })}</div>;
        }
        return loader;
    }

    private static setMinAndMaxTypesPrice(accommodationTypes: AccommodationType[]) {
        if (accommodationTypes.length === 0) {
            return;
        }
        const maxprice = accommodationTypes[0].basePriceInclusive
            ? accommodationTypes[0].basePriceInclusive
            : accommodationTypes[0].nightPriceInclusive
            ? accommodationTypes[0].nightPriceInclusive
            : accommodationTypes[0].baseNightPriceInclusive
            ? accommodationTypes[0].baseNightPriceInclusive
            : undefined;
        const minprice = accommodationTypes[accommodationTypes.length - 1].basePriceInclusive
            ? accommodationTypes[accommodationTypes.length - 1].basePriceInclusive
            : accommodationTypes[accommodationTypes.length - 1].nightPriceInclusive
            ? accommodationTypes[accommodationTypes.length - 1].nightPriceInclusive
            : accommodationTypes[accommodationTypes.length - 1].baseNightPriceInclusive
            ? accommodationTypes[accommodationTypes.length - 1].baseNightPriceInclusive
            : undefined;
        return { minprice, maxprice };
    }

    private refreshAccommodationTypes = (zoomedAccommodationTypes: number[]) => {
        const { accommodationTypes } = this.state;
        const { totalResults } = this.props;
        if (accommodationTypes) {
            const boundedAccommodationTypes = accommodationTypes.filter((accoType) => zoomedAccommodationTypes.indexOf(accoType.resourceId) > -1);
            this.setState({
                mapSynchedAccommodationTypes: boundedAccommodationTypes,
                totalAccommodationTypes: totalResults || 0,
            });
        }
    };

    private static getPreFilters = (props: Readonly<TypesearchContainerProps>) => {
        const { context, options, dynamicFilter } = props;
        const preFilters: PreFilters = {};
        const currentLocale = context?.currentLocale?.locale;
        const siteLocale = context?.site?.localizedOptions;

        const dc =
            options?.localizedOptions?.find((locale) => locale?.locale === currentLocale)?.distributionChannelId ||
            dynamicFilter.distributionChannel?.distributionChannelId ||
            siteLocale?.find((locale) => locale?.locale === currentLocale)?.distributionChannelId;
        if (options?.resortId) {
            preFilters.resortId = options?.resortId;
        }
        if (dc) {
            preFilters.distributionChannelId = Number(dc);
        }
        return preFilters;
    };

    private static async fetchAvailabilityResults(
        props: TypesearchContainerProps,
        stateHandler: StateHandler<TypesearchContainerProps, TypesearchContainerState>,
        dynamicFilter: DynamicFilter,
        env?: ApiCallOptions,
        tthis?: TypesearchContainerWidgetBase
    ): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            stateHandler.setState(
                {
                    availabilityState: props.availabilityState,
                },
                () => TypesearchContainerWidgetBase.updateAccoTypes(props, stateHandler, true, stateHandler.state, tthis).then(resolve).catch(reject)
            );
        });
    }

    private static async filterOutRestrictedResources(props: TypesearchContainerProps, nextResources: ResourceGroup | undefined, env: ApiCallOptions | undefined) {
        const {
            dynamicFilter,
            options,
            context: { mxtsApi },
        } = props;
        let filteredResources = nextResources;
        const restrictedResourceIds: number[] = [];
        if (nextResources?.length && dynamicFilter.startdate && env) {
            const resourceIds = nextResources.map((nextResource) => nextResource.resourceId);
            const getAllBookRestrictions: BookRestrictions[] = await getAllBookRestrictionsByResourceIds(mxtsApi, resourceIds, env);
            if (getAllBookRestrictions.length) {
                for (const resourceId of resourceIds) {
                    const restrictedResources = getAllBookRestrictions.filter((getBookRestriction) => getBookRestriction.resourceId === resourceId);
                    const restrictedOption = validateBookRestrictionConditions(restrictedResources, dynamicFilter);
                    if (restrictedOption === RESTRICTION_RULE_OPTIONS.BOOK_RESTRICTION) {
                        restrictedResourceIds.push(resourceId);
                    }
                }
            }
            if (restrictedResourceIds.length) {
                if (!options.displayBookingRestrictedAccoTypes) {
                    filteredResources = nextResources.filter((resource) => !restrictedResourceIds.includes(resource.resourceId));
                }
            }
        }
        return {
            filteredResources,
            restrictedResourceIds,
        };
    }

    private renderUnvirtualizedList({
        childrenArray,
        slicedAccommodationTypes,
        displayTypeChild,
    }: {
        childrenArray: JSX.Element[];
        slicedAccommodationTypes: AccommodationType[];
        displayTypeChild: string;
    }) {
        return slicedAccommodationTypes.map((accommodationType, index) =>
            this.renderTypeSearchPanel({
                index,
                childrenArray,
                elementsArray: slicedAccommodationTypes,
                displayTypeChild,
            })
        );
    }

    private toggleUnits = (key: string): void => {
        const visibleUnitContainers = [...this.state.toggleChildren];
        if (visibleUnitContainers.includes(key)) {
            visibleUnitContainers.splice(visibleUnitContainers.indexOf(key), 1);
            scroller.scrollTo(key, {
                duration: 500,
                delay: 0,
                smooth: "linear",
            });
        } else {
            visibleUnitContainers.push(key);
        }

        this.setState({ toggleChildren: visibleUnitContainers });
    };

    private renderToggleIcon = (name: string) => {
        const { options } = this.props;
        const { iconColor, iconToRight } = options;
        return (
            <FontAwesome
                name={name}
                className={`fontawesome-${name} icon ${iconToRight ? "ml-2" : "mr-1"} ${fontColorPicker(iconColor)}`}
                style={{ color: iconColor?.includes("rgba") ? iconColor : undefined }}
            />
        );
    };

    // eslint-disable-next-line max-lines-per-function
    private renderTypeSearchPanel({
        index,
        childrenArray,
        elementsArray: slicedAccommodationTypes,
        displayTypeChild,
    }: {
        index: number;
        childrenArray: JSX.Element[];
        elementsArray: AccommodationType[];
        displayTypeChild: string;
    }) {
        const {
            dynamicFilter,
            context: { currentLocale, site },
            options,
            dispatchAction,
            children,
            userInterfaceState,
        } = this.props;
        const { buttonLocation, iconColor, iconPropertiesForLess, iconPropertiesForMore, iconToRight, loadUnits, localizedOptions, showPriceOnButton, unitButtonIcon, unitPageUri } = options;
        const { amenityCodes, toggleChildren, zoomedAccommodationTypes } = this.state;
        const { rateType: { rateTypeId } = {} } = dynamicFilter;
        const localizedDcOptions: LocalizedDcOptions | null = getLocalizedContent({ site, currentLocale, localizedContent: localizedOptions || [] });
        const displayGridListMap = userInterfaceState.resultLayoutViewOptions?.resultLayoutDisplayType;
        const localizedRateTypes: number[] | undefined = getLocalizedContent({
            site,
            currentLocale,
            localizedContent: localizedOptions || [],
            keys: ["rateTypes"],
        })?.rateTypes?.map((rateType) => rateType.value);
        const newRateTypeId: number | undefined = localizedRateTypes?.[0] || rateTypeId;
        const unitBookUri: string = unitPageUri;
        const accommodationType = slicedAccommodationTypes[index];
        const moreIcon = iconPropertiesForMore && this.renderToggleIcon(iconPropertiesForMore);
        const lessIcon = iconPropertiesForLess && this.renderToggleIcon(iconPropertiesForLess);
        const showMoreIcon =
            unitButtonIcon && !iconPropertiesForMore ? (
                <FontAwesome name="plus" className={`${iconToRight ? "ml-2" : "mr-1"} ${fontColorPicker(iconColor)}`} style={{ color: iconColor?.includes("rgba") ? iconColor : undefined }} />
            ) : (
                moreIcon
            );
        const showLessIcon =
            unitButtonIcon && !iconPropertiesForLess ? (
                <FontAwesome name="minus" className={`${iconToRight ? "ml-2" : "mr-1"} ${fontColorPicker(iconColor)}`} style={{ color: iconColor?.includes("rgba") ? iconColor : undefined }} />
            ) : (
                lessIcon
            );

        const typesearchContainerCRPProps: CrpProps = {
            useCrpProps: true,
            accommodationType,
            newRateTypeId,
            unitBookUri,
            key: accommodationType ? accommodationType.resourceId : generateRandomKey(),
            amenityCodes: amenityCodes || [],
            dispatchOpenLinkedTabAction: dispatchOpenLinkedTabAction({ resourceId: accommodationType.resourceId, dispatchAction }),
        };

        const childrenWithProps = React.Children.map(childrenArray, (child) =>
            React.cloneElement((child as React.ReactElement<any>).props.children, { ...typesearchContainerCRPProps, dynamicFilter })
        );

        const uniqueId = `accommodation_${accommodationType?.resourceId}`;
        const loadUnitsButton = (
            <div className="type-search-button-wrap">
                <Button onClick={() => this.toggleUnits(uniqueId)} className={`button type-search-button ${buttonLocation}`}>
                    {showPriceOnButton && !toggleChildren.includes(uniqueId) && (
                        <div className="price-on-type-button">
                            <span className="price-label">{getI18nLocaleString(namespaceList.widgetTypeSearch, "priceLabel", currentLocale, site)}</span>
                            <span className="price">{`${accommodationType?.priceInclusive}`}</span>
                            <span className="price-symbol">,- </span>
                        </div>
                    )}
                    {unitButtonIcon && !iconToRight ? (toggleChildren.includes(uniqueId) ? showLessIcon : showMoreIcon) : undefined}
                    {this.getLoadUnitsButtonText({ localizedDcOptions, uniqueId, accommodationType, currentLocale, site })}
                    {unitButtonIcon && iconToRight ? (toggleChildren.includes(uniqueId) ? showLessIcon : showMoreIcon) : undefined}
                </Button>
            </div>
        );
        return (
            <ErrorBoundary key={`type-search-panel-error-boundary-${index}`}>
                <div
                    onMouseEnter={
                        displayGridListMap === "list-view-map" || displayGridListMap === "grid-view-map" ? this.highlightMarker.bind(this, accommodationType.resourceId, "zoom-in") : undefined
                    }
                    onMouseLeave={
                        displayGridListMap === "list-view-map" || displayGridListMap === "grid-view-map" ? this.highlightMarker.bind(this, accommodationType.resourceId, "zoom-out") : undefined
                    }
                    // eslint-disable-next-line max-len
                    className={classNames(
                        zoomedAccommodationTypes?.includes(accommodationType.resourceId) ? "fade-in" : "fade-out",
                        displayTypeChild,
                        accommodationType.reservable === false ? "unreservable" : "reservable"
                    )}
                >
                    {childrenWithProps}
                    <div className="type-search-wrapper__button">
                        {loadUnits && buttonLocation === "above" && loadUnitsButton}
                        {toggleChildren.includes(uniqueId) &&
                            children?.map((child) =>
                                React.cloneElement(child.element as React.ReactElement<any>, {
                                    accommodationType,
                                    key: accommodationType?.resourceId || generateRandomKey(),
                                    options: child.options,
                                })
                            )}
                        {loadUnits && buttonLocation === "below" && loadUnitsButton}
                    </div>
                </div>
            </ErrorBoundary>
        );
    }

    private static getMarkerTypeSearchPanel(props: TypesearchContainerProps, stateHandler: StateHandler<TypesearchContainerProps, TypesearchContainerState>, accommodationType: any, ind: number) {
        const { childrenList, options, dynamicFilter } = props;
        const { showMap } = stateHandler.state;
        const unitBookUri: string = options.unitPageUri;
        const childrenWithProps = React.Children.map(childrenList, (child) =>
            React.cloneElement((child as React.ReactElement<any>).props.children, {
                accommodationType,
                unitBookUri,
                dynamicFilter,
                key: accommodationType ? accommodationType.resourceId : generateRandomKey(),
            })
        );
        if (showMap && childrenList.length && accommodationType) {
            return (
                <div key={ind} className={accommodationType.reservable === false ? "unreservable" : "reservable"}>
                    {childrenWithProps}
                </div>
            );
        }
    }

    private getLoadUnitsButtonText = (unitsButtonTextParams: {
        localizedDcOptions: LocalizedDcOptions | null;
        uniqueId: string;
        accommodationType: AccommodationType;
        currentLocale: CurrentLocale;
        site: Site & WithId;
    }) => {
        const { toggleChildren } = this.state;
        const { localizedDcOptions, uniqueId, accommodationType, currentLocale, site } = unitsButtonTextParams;
        let unitsButtonText: string | undefined = "";
        unitsButtonText = toggleChildren.includes(uniqueId) ? localizedDcOptions?.sentenceForHide : localizedDcOptions?.sentenceForShow;
        if (!toggleChildren.includes(uniqueId) && accommodationType?.units?.length === 1 && localizedDcOptions?.sentenceForShowSingular?.includes("[x]")) {
            unitsButtonText = localizedDcOptions.sentenceForShowSingular;
        }
        if (toggleChildren.includes(uniqueId) && accommodationType?.units?.length === 1 && localizedDcOptions?.sentenceForHideSingular?.includes("[x]")) {
            unitsButtonText = localizedDcOptions.sentenceForHideSingular;
        }
        if (unitsButtonText?.includes("[x]")) {
            unitsButtonText = unitsButtonText?.replace(/\[x]/g, `${accommodationType?.units?.length}`);
        }
        if (unitsButtonText?.includes("[a]")) {
            unitsButtonText = unitsButtonText?.replace(/\[a]/g, getI18nLocaleString(namespaceList.widgetAssetPublisher, "all", currentLocale, site).toLowerCase());
        }
        return unitsButtonText;
    };

    private highlightMarker(resourceId: number, zoomType: string): void {
        const action: FilterChangeAction = {
            type: ActionType.FilterChange,
            filter: dynamicFilterType.onMouseOverOut,
            payload: {
                onMouseOverOut: zoomType === "zoom-in" ? resourceId : undefined,
            },
        };
        this.props.dispatchAction(action);
        // this.handleDispatchLocation(accommodationTypes!);
    }

    private static handleDispatchDynamicMarkers(
        props: TypesearchContainerProps,
        stateHandler: StateHandler<TypesearchContainerProps, TypesearchContainerState>,
        accommodationTypes: AccommodationType[],
        isAllResultFetched: boolean
    ) {
        const markers: MarkerDetails[] = accommodationTypes.length
            ? accommodationTypes.map((accoType, ind) => ({
                  markerId: accoType.resourceId,
                  resourceId: accoType.resourceId,
                  position: {
                      lat: accoType.resourceLocation?.lat || 0,
                      lng: accoType.resourceLocation?.lon || 0,
                  },
                  markerDetails: TypesearchContainerWidgetBase.getMarkerTypeSearchPanel(props, stateHandler, accoType, ind),
                  title: accoType.resortName,
                  showDetail: false,
              }))
            : [];
        const locationAction: FilterChangeAction = {
            type: ActionType.FilterChange,
            filter: dynamicFilterType.dynamicMarkersIsFetchComplete,
            payload: {
                dynamicMarkers: markers,
                isAllResultFetched,
            },
        };
        props.dispatchAction(locationAction);
    }

    // eslint-disable-next-line max-len
    private static async fetchDetails(
        props: TypesearchContainerProps,
        stateHandler: StateHandler<TypesearchContainerProps, TypesearchContainerState>,
        state: TypesearchContainerState,
        env: ApiCallOptions | undefined,
        fetchParameters: FetchParameters,
        tthis?: TypesearchContainerWidgetBase
    ): Promise<FetchResult> {
        const { mxtsApi } = props.context;
        const { stayPeriodDef, address } = fetchParameters;
        const response: AvailabilityResult | undefined = state.availabilityState.availabilityResult;
        const { fetchedResources } = stateHandler.state;
        const fetchResourcesDetails = async (): Promise<ResourceGroup | Array<Partial<Resource & Document>>> => {
            let fetchedResourcesDetails: ResourceGroup | Array<Partial<Resource & Document>> = [];
            if (response && env) {
                const resources: ResourceGroup | undefined = response.response.resources;
                const specialResourceIds: number[] = [];
                // Checking if resources are in state then only fetch the ones which are not in state
                if (fetchedResources?.length && resources?.length) {
                    const onlyResourceIds: number[] = fetchedResources.map((res: Resource) => res!.resourceId);
                    resources.forEach((availableResource) => {
                        if (availableResource.specialIds) {
                            availableResource.specialIds.forEach((specialId) => {
                                if (specialResourceIds.indexOf(specialId) === -1 && onlyResourceIds.indexOf(specialId) === -1) {
                                    specialResourceIds.push(specialId);
                                }
                            });
                        }
                    });
                } else {
                    resources!.forEach((resource) => {
                        if (resource.specialIds) {
                            resource.specialIds.forEach((specialId) => {
                                // To avoid pushing duplicate specials
                                if (specialResourceIds.indexOf(specialId) === -1) {
                                    specialResourceIds.push(specialId);
                                }
                            });
                        }
                    });
                }

                fetchedResourcesDetails = tthis?.props.availabilityState.availabilityResult?.response.resources || tthis?.state.availabilityState.availabilityResult?.response.resources || [];
                if (specialResourceIds.length) {
                    const specials = await DomainObjectUtil.getResourcesByIds(mxtsApi, specialResourceIds, env);
                    if (specials) {
                        fetchedResourcesDetails = [...fetchedResourcesDetails, ...specials];
                    }
                }
            }
            return fetchedResourcesDetails;
        };

        const fetchAccoKinds = async (): Promise<AccoKind[]> =>
            env ? (await mxtsApi.accommodationkinds(env, { sort: "priority", size: MXTS.MAX_RESULTS }, undefined, tthis?.controller?.signal)).content : [];

        const fetchStayPeriodDefs = async (): Promise<StayPeriodDef[]> =>
            stayPeriodDef.isIncluded && env
                ? await mxtsApi.stayPeriodDefs(env, { stayPeriodDefIds: stayPeriodDef.stayPeriodDefIds }, undefined, tthis?.controller?.signal).then((result) => result.content)
                : [];

        const fetchAllResortsAndAddresses = async () => {
            let allResorts: Resort[] = [];
            let allAddresses: Address[] = [];
            if (env) {
                if (address.isIncluded) {
                    const resortAndAddresses: WithAddressResult<PagedResult<Resort>> = await getAllWithAddress((size: number, page: number) =>
                        mxtsApi.resortsWithAddress(env, { size, page }, undefined, tthis?.controller?.signal)
                    );
                    allResorts = resortAndAddresses?.data?.content || [];
                    allAddresses = resortAndAddresses?.addresses || [];
                } else {
                    allResorts = env ? await mxtsApi.resorts(env, { size: MXTS.MAX_RESULTS }, undefined, tthis?.controller?.signal).then((result) => result.content) : [];
                }
            }
            return { allResorts, allAddresses };
        };

        const [fetchedResourcesDetails, accoKinds, fetchedStayPeriodDefs, allResortsAndAddresses] = await Promise.all([
            fetchResourcesDetails(),
            fetchAccoKinds(),
            fetchStayPeriodDefs(),
            fetchAllResortsAndAddresses(),
        ]);
        const { allResorts, allAddresses } = allResortsAndAddresses;

        return { resorts: allResorts, stayPeriodDefs: fetchedStayPeriodDefs, addresses: allAddresses, accoKinds, fetchedResources: [...(fetchedResources || []), ...fetchedResourcesDetails] as any };
    }

    // eslint-disable-next-line max-lines-per-function
    private static async updateAccoTypes(
        props: TypesearchContainerProps,
        stateHandler: StateHandler<TypesearchContainerProps, TypesearchContainerState>,
        fetchAvailability = false,
        nextState: TypesearchContainerState,
        tthis?: TypesearchContainerWidgetBase
    ): Promise<void> {
        const env: ApiCallOptions | undefined = stateHandler.state.availabilityState.env || (await getMxtsEnv(props.context));
        const nextResponse: AvailabilityResult | undefined = nextState.availabilityState.availabilityResult;
        const stayPeriodDefIds: Facet = (nextResponse ? nextResponse.response.stayPeriodDefs : []) || [];
        const { filteredResources: nextResources, restrictedResourceIds } =
            (await TypesearchContainerWidgetBase.filterOutRestrictedResources(props, nextResponse ? nextResponse.response.resources : [], env)) || [];

        if (nextResponse?.response?.resources?.length === 0) {
            await TypesearchContainerWidgetBase.errorHandler(props, stateHandler);
        }
        const fetchParameters: FetchParameters = {
            stayPeriodDef: {
                stayPeriodDefIds,
                isIncluded: true,
            },
            address: {
                isIncluded: true,
            },
        };
        const amenities = stateHandler.state.availabilityState.availabilityResult?.response.amenities;
        if (props.options.showAmenitiesCount && amenities?.length) {
            const action: AmenitiesAction = {
                type: ActionType.AmenitiesFetch,
                payload: amenities,
            };
            props.dispatchAction(action);
        }
        // eslint-disable-next-line max-lines-per-function
        const applyFetchedDetailsToState = (details: FetchResult) => {
            let allAccommodationTypes: AccommodationType[] = [];
            if (nextResources && fetchAvailability) {
                const { resorts, stayPeriodDefs, addresses, accoKinds, fetchedResources } = details;

                if (!getGlobalFields().pageLoadTillTypeSearchPopulatedTime && isClientSide() && isOnLandingPage()) {
                    getGlobalFields().pageLoadTillTypeSearchPopulatedTime = performance.now();
                    globalLogger.info(
                        `[${getDeviceName(props.context)}] (${location.hostname + location.pathname}) Time from navigation start till typeSearchContainer populated: ${performance.now() / 1000} s`
                    );
                }

                if (fetchedResources?.length) {
                    allAccommodationTypes = nextResources.map((resource: any) => {
                        const accoTypeResource: Resource | null = resourceById(fetchedResources!, resource.resourceId);
                        const accommodationkind: AccoKind | null = accoKindById(accoKinds ? accoKinds : [], resource.accommodationkindId);
                        const resort: Resort | undefined = resorts.find((availableResort) => availableResort.resortId === resource.resortId);
                        const special: Resource | null = fetchedResources ? resourceById(fetchedResources, resource.specialId) : null;
                        const specials: Resource[] | null = resource.specialIds.map((specialId: number) => resourceById(fetchedResources, specialId)).filter((special: Resource) => special);
                        const stayPeriodDef: StayPeriodDef | null = stayPeriodDefById(stayPeriodDefs ? stayPeriodDefs : [], resource.stayPeriodDefId);
                        const address: Address | null = resource === null || !resort ? null : addressByManagerId(addresses ? addresses : [], resort.visitAddressManagerId);
                        return {
                            ...accoTypeResource!,
                            ...resource,
                            units: [],
                            resortName: resort?.name || "",
                            accommodationkindName: accommodationkind?.name || "",
                            specials,
                            specialCode: special ? [special?.searchCode || special?.code] : "",
                            specialName: special?.name || "",
                            specialDescription: special?.description || "",
                            specialDescription2: special?.description2 || "",
                            specialShortDescription: special?.shortDescription || "",
                            stayPeriodDefName: stayPeriodDef?.name || "",
                            stayPeriodDefCode: stayPeriodDef?.code || "",
                            city: address?.city || "",
                            isBookingRestricted: restrictedResourceIds.some((resourceId: number) => resourceId === resource.resourceId),
                            localizedBookingIsRestrictedMsg: props.options.localizedBookingIsRestrictedMsg,
                        };
                    });
                    let typesPrice;
                    if (allAccommodationTypes.length) {
                        const { maxPrice, minPrice } = tthis?.props.availabilityState?.availabilityResult?.response ?? {};
                        if (minPrice && maxPrice) {
                            typesPrice = { minprice: minPrice, maxprice: maxPrice };
                        } else {
                            typesPrice = TypesearchContainerWidgetBase.setMinAndMaxTypesPrice(allAccommodationTypes);
                        }
                    }
                    stateHandler.setState(
                        {
                            isLoading: false,
                            infiniteLoading: false,
                            accommodationTypes: allAccommodationTypes,
                            totalAccommodationTypes: props.totalResults || allAccommodationTypes.length,
                            mapSynchedAccommodationTypes: allAccommodationTypes,
                            zoomedAccommodationTypes: (allAccommodationTypes || []).map((accoType) => accoType.resourceId),
                            resorts,
                            stayPeriodDefs,
                            addresses,
                            fetchedResources,
                            nextResources,
                            minPrice: typesPrice ? typesPrice.minprice : stateHandler.state.minPrice,
                            maxPrice: typesPrice ? typesPrice.maxprice : stateHandler.state.maxPrice,
                        },
                        () => {
                            const resultLayoutDisplayType = props.userInterfaceState.resultLayoutViewOptions?.resultLayoutDisplayType;
                            if (resultLayoutDisplayType === "list-view-map" || resultLayoutDisplayType === "grid-view-map") {
                                TypesearchContainerWidgetBase.handleDispatchDynamicMarkers(props, stateHandler, stateHandler.state.accommodationTypes!, true);
                            }
                        }
                    );
                }
                return;
            }
            // If certain filters are applied, that don't need to get new Availability
            const { accommodationTypes } = stateHandler.state;
            let filterAccommodationTypes: AccommodationType[] = [];
            if (props.dynamicFilter.selectedDirectSearchId) {
                const selectedDirectSearchId: number = props.dynamicFilter.selectedDirectSearchId;
                filterAccommodationTypes = accommodationTypes!.filter((accoType) => accoType.resourceId === selectedDirectSearchId);
            }
            if (props.dynamicFilter.directSearchInput) {
                const directSearchInput: string = props.dynamicFilter.directSearchInput;
                filterAccommodationTypes = accommodationTypes!.filter((accoType) => accoType.name.toLowerCase().includes(directSearchInput.toLowerCase()));
            }
            if (props.dynamicFilter.minprice && props.dynamicFilter.maxprice) {
                const priceRangeCriteria: string | undefined = props.dynamicFilter?.priceRangeCriteria;
                const criteria =
                    priceRangeCriteria === PriceRangeCriteria.BASE_PRICE_INCLUSIVE
                        ? PriceType.PRICE_INCLUSIVE
                        : priceRangeCriteria === PriceRangeCriteria.AVERAGE_NIGHT_PRICE
                        ? PriceType.BASE_NIGHT_PRICE_INCLUSIVE
                        : undefined;
                filterAccommodationTypes = accommodationTypes
                    ? accommodationTypes.filter((accType) => {
                          const accTypePrice: number | undefined = criteria ? accType[criteria] : accType.price ? accType.price : accType.nightPrice ? accType.nightPrice : undefined;
                          if (accTypePrice && props.dynamicFilter.minprice && props.dynamicFilter.maxprice) {
                              return accTypePrice >= props.dynamicFilter.minprice && accTypePrice <= props.dynamicFilter.maxprice;
                          }
                      })
                    : [];
            }
            const zoomedAccommodationTypes: number[] = (filterAccommodationTypes || []).map((accoType) => accoType.resourceId);
            let filteredTypesPrice;
            // Set min and max price out of the filtered types and update state
            if (filterAccommodationTypes.length) {
                filteredTypesPrice = TypesearchContainerWidgetBase.setMinAndMaxTypesPrice(filterAccommodationTypes);
                stateHandler.setState(
                    {
                        mapSynchedAccommodationTypes: filterAccommodationTypes && filterAccommodationTypes.length ? filterAccommodationTypes : accommodationTypes,
                        zoomedAccommodationTypes,
                        minPrice: filteredTypesPrice ? filteredTypesPrice.minprice : stateHandler.state.minPrice,
                        maxPrice: filteredTypesPrice ? filteredTypesPrice.maxprice : stateHandler.state.maxPrice,
                    },
                    () => {
                        const resultLayoutDisplayType = props.userInterfaceState.resultLayoutViewOptions?.resultLayoutDisplayType;
                        if (resultLayoutDisplayType === "list-view-map" || resultLayoutDisplayType === "grid-view-map") {
                            TypesearchContainerWidgetBase.handleDispatchDynamicMarkers(props, stateHandler, filterAccommodationTypes, true);
                        }
                    }
                );
            }
        };

        // const { fetchedResources } = stateHandler.state;
        // const fetchedResourcesDetails = tthis?.props.availabilityState.availabilityResult?.response.resources || tthis?.state.availabilityState.availabilityResult?.response.resources || [];
        // First make sure the accoTypes are displayed on the page
        // applyFetchedDetailsToState({ resorts: [], accoKinds: [], addresses: [], fetchedResources: [...(fetchedResources || []), ...fetchedResourcesDetails] as any, stayPeriodDefs: [] });

        tthis?.cancelFetchingDetails?.();
        const [fetchDetails, cancelFetchingDetails] = cancelable(TypesearchContainerWidgetBase.fetchDetails(props, stateHandler, nextState, env, fetchParameters, tthis));
        if (tthis) {
            tthis.cancelFetchingDetails = cancelFetchingDetails;
        }
        const fetchedDetails = await fetchDetails;
        applyFetchedDetailsToState(fetchedDetails);
        // Now populate the accoType details without using await so we are not delaying the rendering of the accoType list
        // fetchDetails.then((fetchedDetails) => {
        //     applyFetchedDetailsToState(fetchedDetails);
        // });
    }

    private static async errorHandler(props: TypesearchContainerProps, stateHandler: StateHandler<TypesearchContainerProps, TypesearchContainerState>): Promise<void> {
        const { alerts, currentLocale, site } = props.context;
        const webContent: (CmsApiWebContent & WithId) | null = await TypesearchContainerWidgetBase.getNoDataFoundContent(props);
        const template: JSX.Element[] | null = await TypesearchContainerWidgetBase.getNoDataFoundTemplate(props);
        if (webContent || template) {
            alerts.push({
                color: "danger",
                message: getI18nLocaleString(namespaceList.widgetSearchfacet, "noDataFound", currentLocale, site),
            });
        }
        stateHandler.setState(
            {
                webContent: webContent || undefined,
                template: template || undefined,
                errorFetchingTypes: "error",
                isLoading: false,
                accommodationTypes: [],
                mapSynchedAccommodationTypes: [],
                zoomedAccommodationTypes: [],
                totalAccommodationTypes: 0,
            },
            () => {
                const resultLayoutDisplayType = props.userInterfaceState.resultLayoutViewOptions?.resultLayoutDisplayType;
                if (resultLayoutDisplayType === "list-view-map" || resultLayoutDisplayType === "grid-view-map") {
                    TypesearchContainerWidgetBase.handleDispatchDynamicMarkers(props, stateHandler, stateHandler.state.accommodationTypes!, true);
                }
            }
        );
    }

    private static async getNoDataFoundContent(props: TypesearchContainerProps): Promise<(CmsApiWebContent & WithId) | null> {
        const { webContentId } = props.options;
        if (webContentId) {
            return props.context.cmsApi.webContentApi.findById({ id: webContentId });
        }
        return null;
    }

    private static async getNoDataFoundTemplate(props: TypesearchContainerProps): Promise<JSX.Element[] | null> {
        const { templateId } = props.options;
        if (templateId) {
            const template = await props.context.cmsApi.templateApi.findById({ id: templateId });
            if (template) {
                return await renderPageWidgets(template.root, props.context);
            }
        }
        return null;
    }

    private static async handleAmenities(
        props: TypesearchContainerProps,
        stateHandler: StateHandler<TypesearchContainerProps, TypesearchContainerState>,
        amenityIds: string[],
        tthis?: TypesearchContainerWidgetBase
    ): Promise<void> {
        const context = props.context;
        const apiCallOptions: ApiCallOptions = await getMxtsEnv(context);
        if (amenityIds) {
            // Amenity codes are absent in dynamicFilter, fetching them to pass them towards BE
            const amenityCodes: string[] = [];
            if (amenityIds?.length) {
                const fetchedAmenities: Amenity[] = await context.mxtsApi
                    .amenities(apiCallOptions, { identifier: amenityIds.join(",") }, undefined, tthis?.controller?.signal)
                    .then((am) => am.content);
                if (fetchedAmenities) {
                    fetchedAmenities.forEach((element: Amenity) => {
                        amenityCodes.push(element.identifier);
                    });
                }
            }
            stateHandler.setState({ amenityCodes });
        }
    }

    // eslint-disable-next-line max-lines-per-function
    private static async populateDynamicFilterWithPreSelectedFilters(props: TypesearchContainerProps): Promise<DynamicFilter> {
        const {
            context: { currentLocale, site, mxtsApi },
            options: { holidayCode, resortId, regionId, localizedOptions, minCapacity, defaultNumberOfTypes, nextNumberOfTypes },
            dynamicFilter: { rateType: { rateTypeId } = {}, distributionChannel: { distributionChannelId } = {}, stay },
        } = props;
        let filter: DynamicFilter = {};

        if (!isEmpty(props.options.specialCodes)) {
            filter.specialcode = props.options.specialCodes!.map((code) => code.value);
        }

        if (regionId) {
            filter.regionIds = [+regionId];
        }

        if (resortId) {
            filter.resortids = [+resortId];
        }
        if (minCapacity) {
            filter.minCapacity = minCapacity;
        }
        const preFilters = TypesearchContainerWidgetBase.getPreFilters(props);
        filter.paginatedData = {
            typesearch: {
                size: {
                    incrementalSize: +(nextNumberOfTypes || 5),
                    initialSize: +(defaultNumberOfTypes || 5),
                },
                preFilters,
            },
        };
        filter = TypesearchContainerWidgetBase.mergeOptionsWithDynamicFilter(props, filter);
        const env: ApiCallOptions = await getMxtsEnv(props.context, props.context.currentLocale.code);
        const { defaultStay, stayFilter } = await getDefaultStayCode(env, mxtsApi, filter, props.options?.defaultStay, stay);
        const stayPeriodDefFilters = await getStayPeriodDefFilters(mxtsApi, { defaultStay, env, dynamicFilter: props.dynamicFilter, holidayCode });
        Object.entries(stayPeriodDefFilters).forEach(([key, value]: ["stayperioddefid" | "stayHolidayPeriodDefId", number]) => {
            filter[key] = value;
        });
        if (Object.keys(stayPeriodDefFilters).length) {
            filter = { ...filter, ...stayFilter, ...stayPeriodDefFilters };
        }
        const payload: DynamicFilter = { ...props.dynamicFilter, ...filter, shouldFetchUnitsWithPrice: false };
        const localizedDcOptions: LocalizedDcOptions | null = getLocalizedContent({ site, currentLocale, localizedContent: localizedOptions || [] });
        // If widget options has DC already selected, dispatching that DC
        const newRateTypeId = localizedDcOptions?.rateTypes?.length ? localizedDcOptions.rateTypes[0].value : rateTypeId;
        if (newRateTypeId && newRateTypeId !== rateTypeId) {
            const rateType = await mxtsApi
                .rates(env, {
                    rateTypeId: newRateTypeId,
                })
                .then((rates) => rates?.content?.[0])
                .catch((error: Error) => {
                    props.context.logger.error(error);
                    return undefined;
                });
            if (rateType) {
                payload.rateType = { rateTypeId: rateType.rateTypeId, code: rateType.code };
            }
        }
        let localizedDcId = localizedDcOptions?.distributionChannelId;
        if (isMobileDeviceDetected() && localizedDcOptions?.mobileDCId) {
            localizedDcId = localizedDcOptions?.mobileDCId;
        }
        const newDistributionChannelId = localizedDcId || distributionChannelId;
        if (newDistributionChannelId && newDistributionChannelId !== distributionChannelId) {
            const distributionChannel = await mxtsApi.distributionChannel(env, {}, [{ key: "dcId", value: newDistributionChannelId }]).catch((error: Error) => {
                props.context.logger.error(error);
                return undefined;
            });
            if (distributionChannel) {
                payload.distributionChannel = { distributionChannelId: distributionChannel.distributionChannelId, code: distributionChannel.code };
            }
        }
        return payload;
    }

    private static mergeOptionsWithDynamicFilter(props: TypesearchContainerProps, dynamicFilter: DynamicFilter): DynamicFilter {
        const amenityIds: string[] | undefined = props.options.amenities ? props.options.amenities.map((amenity) => amenity.value.toString()) : undefined;
        const minArrivalDate: string | undefined = props.options.minArrivalDate ? moment(props.options.minArrivalDate).format(DATE_FORMAT.ELASTIC) : undefined;
        const maxArrivalDate: string | undefined = props.options.maxArrivalDate ? moment(props.options.maxArrivalDate).format(DATE_FORMAT.ELASTIC) : undefined;
        const accoKinds: number[] | undefined = props.options.accoKinds ? props.options.accoKinds.map((accoKind) => +accoKind.value) : undefined;
        const showUnavailableTypes: boolean | undefined = props.context.site.showUnavailableTypes;
        const guestRating = props.options.minGuestRating ? +props.options.minGuestRating : undefined;
        // cloning dynamicFilter into filter to prevent redux state modification through dynamicFilter object reference
        const filter: DynamicFilter = { ...dynamicFilter };
        if (amenityIds?.length) {
            const dynamicAmenities: string[] = props.dynamicFilter.amenities ? props.dynamicFilter.amenities.filter((am) => amenityIds.indexOf(am) === -1) : [];
            filter.amenities = [...dynamicAmenities, ...amenityIds];
        }
        if (minArrivalDate) {
            filter.minimumArrivalDate = minArrivalDate;
        }
        if (maxArrivalDate) {
            filter.maximumArrivalDate = maxArrivalDate;
        }
        if (accoKinds?.length) {
            filter.accokindids = accoKinds;
        }
        if (showUnavailableTypes) {
            filter.includeBookedPeriods = true;
        }
        const extraAggregations = [];
        if (props.options.sortOnArrivalDate) {
            extraAggregations.push(SORTED_RESOURCE_AGGREGATION);
        }
        if (props.options.showAmenitiesCount) {
            extraAggregations.push(AMENITIES_COUNT_AGGREGATION);
        }
        if (extraAggregations.length > 0) {
            filter.extraAggregations = extraAggregations;
        }
        if (guestRating) {
            filter.guestRating = guestRating;
        }
        if (!isEmpty(props.options.specialCodes)) {
            filter.specialcode = props.options.specialCodes!.map((code) => code.value);
        }
        return filter;
    }
}

function mapStateToProps(state: State): TypesearchContainerStoreProps {
    return {
        dynamicFilter: state.dynamicFilter,
        availabilityState: state.availabilityState,
        userInterfaceState: state.userInterfaceState,
        amenities: state.amenitiesState.amenities,
    };
}

function mapDispatchToProps(dispatch: Dispatch<FilterChangeAction | AvailabilityAction | ResultsPanelAction>): TypesearchContainerDispatchProps {
    return { dispatchAction: dispatch };
}

// eslint-disable-next-line max-len
const TypesearchContainer = connect<TypesearchContainerStoreProps, TypesearchContainerDispatchProps>(mapStateToProps, mapDispatchToProps)(TypesearchContainerWidgetBase);

export const TypesearchContainerWidget = withPagination(withVirtualization(wrapProps<TypesearchContainerBaseProps & TypesearchContainerStoreProps>(TypesearchContainer)));
