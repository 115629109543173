import { State } from "../../redux";
import { createSelector } from "reselect";

export const selectDynamicFilter = (state: State) => state.dynamicFilter;
export const selectAvailabilityState = (state: State) => state.availabilityState;

export const selectFilters = createSelector([selectDynamicFilter, selectAvailabilityState], (dynamicFilter, availabilityState) => ({
    dynamicFilter,
    availabilityState,
}));
